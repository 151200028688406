import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {Text5} from '../components/shared'
import styled from 'styled-components'

const Terms = () => {
  const data = useStaticQuery(
    graphql`
      query PrivacyQuery {
        legal: datoCmsLegal {
          privacyPolicyNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `
  )
  return (
    <PrivacyContainer>
      <Text5 dangerouslySetInnerHTML={{__html: data.legal.privacyPolicyNode.childMarkdownRemark.html}}/>
    </PrivacyContainer>
  )
}

const PrivacyContainer = styled.div`
  padding: 48px;
`

export default Terms
